export interface IUserProfileDataSourcesStatus {
  text:string,
  status: 'deactivated' | 'pending' | 'active' | 'inactive' | 'notActivated' | 'archived'
  icon: string,
}

export const USER_PROFILE_SIDEBAR = [
  {
    name: 'user-profile-general',
    path: '/profile/general',
    title: 'General',
  },
  {
    name: 'user-profile-accounts',
    path: '/profile/accounts',
    title: 'Accounts',
  },
  {
    name: 'user-profile-data-sources',
    path: '/dsm/my-accesses',
    title: 'My Accesses',
  },
];

export type DataSourceStatusesId = 0 | 1 | 2 | 3 | 4 | 5;

export const USER_PROFILE_DATA_SOURCES_STATUSES: {[key in DataSourceStatusesId]: IUserProfileDataSourcesStatus} = {
  0: {
    text: 'Created',
    status: 'inactive',
    icon: 'stop',
  },
  1: {
    text: 'Pending',
    status: 'inactive',
    icon: 'stop',
  },
  2: {
    text: 'Processing',
    status: 'pending',
    icon: 'pause',
  },
  3: {
    text: 'Active',
    status: 'active',
    icon: 'checkmark',
  },
  4: {
    text: 'Archived',
    status: 'archived',
    icon: 'arrow-down',
  },
  5: {
    text: 'Failed',
    status: 'deactivated',
    icon: 'close',
  },
};
