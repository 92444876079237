import dsMenu from '@/modules/data-wizard/data-sources.menu';
import LocalStorage from './services/LocalStorage';

const menu = [
    {
        title: 'Marketing Dashboards',
        icon: 'icon-dashboard.svg',
        childAccountOnly: true,
        subMenu: [
            {
                name: '/dashboards/sales',
                title: 'Carts & Orders',
                sidebarMenu: {
                    openSubMenu: true,
                    items: [
                        {
                            name: '/dashboards/sales/carts',
                            title: 'Carts',
                            permission: 'marketing-dashboards:carts-orders:carts',
                        },
                        {
                            name: '/dashboards/sales/orders',
                            title: 'Orders',
                            permission: 'marketing-dashboards:carts-orders:orders',
                        },
                    ],
                },
            },
            {
                name: '/dashboards/traffic',
                title: 'Traffic & Search',
                sidebarMenu: {
                    openSubMenu: true,
                    items: [
                        {
                            name: '/dashboards/traffic/domain-statistic',
                            title: 'Domain Statistic',
                            permission: 'marketing-dashboards:traffic:domain-stats',
                        },
                        {
                            name: '/dashboards/traffic/search-statistic',
                            title: 'Search Statistics',
                            permission: 'marketing-dashboards:traffic:search-stats',
                        },
                    ],
                },
            },
        ],
    },
    {
        title: 'Marketing Tools',
        icon: 'icon-tools.svg',
        type: 'users',
        childAccountOnly: true,
        subMenu: [
            {
                name: '/tools',
                title: 'Url / Route / Skin',
                sidebarMenu: {
                    openSubMenu: true,
                    items: [
                        {
                            name: '/tools/skin-2-url',
                            title: 'Route/Skin to URL',
                            permission: 'marketing-tool:url-skin:skin-route',
                        },
                        {
                            name: '/tools/url-2-skin',
                            title: 'URL to Route/Skin',
                            permission: 'marketing-tool:url-skin:route-skin',
                        },
                    ],
                },
            },
        ],
    },
    {
        title: 'Tracking',
        icon: 'icon-tracking.svg',
        childAccountOnly: true,
        subMenu: [
            {
                name: '/tracking/catalog',
                title: 'Catalog',
                permission: 'tracking:rules:rule:view',
            },
            {
                name: '/tracking/events',
                title: 'Events',
                permission: ['tracking:rules:rule', 'tracking:rules:event'],
            },
            {
                name: '/tracking/rules',
                title: 'Rules',
                permission: 'tracking:rules:rule',
            },
            {
                name: '/tracking/parameters',
                title: 'Parameters',
                sidebarMenu: {
                    invisible: true,
                    openSubMenu: true,
                    items: [
                        {
                            name: '/tracking/parameters/event',
                            title: 'Event Parameters',
                            permission: 'tracking:rules:var',
                        },
                        {
                            name: '/tracking/parameters/global',
                            title: 'Global Parameters',
                            permission: 'tracking:rules:var',
                        },
                    ],
                },
            },
            {
                name: '/tracking/forms',
                title: 'Forms',
                permission: 'tracking:rules:rule:view',
            },
            {
                name: '/tracking/data-sources',
                title: 'Data Sources',
                permission: 'tracking:domains:domain',
            },
            {
                name: '/tracking/settings',
                title: 'Settings',
                sidebarMenu: {
                    openSubMenu: true,
                    items: [
                        {
                            name: '/tracking/settings/config',
                            title: 'Tracking Settings',
                            permission: 'tracking:settings:config',
                        },
                        {
                            name: '/tracking/settings/label-categories',
                            title: 'Tracking Label Categories',
                            permission: 'tracking:settings:label-categories',
                        },
                        {
                            name: '/tracking/settings/labels',
                            title: 'Tracking Label',
                            permission: 'tracking:settings:label-categories',
                        },
                    ],
                },
            },
            // visible only for KMTX account
            {
                name: '/tracking/collector-versions',
                title: 'Collector RC',
                onlyServiceMaster: true,
                permission: 'tracking:settings:config',
            },
            {
                name: '/tracking/debugger-mobile',
                title: 'App Visual Tagger',
                permission: 'msdk:connection:socket:create',
            },
            {
                name: '/tracking/sdk-debugger',
                title: 'SDK Events',
                permission: 'msdk:connection:socket:create',
            },
        ],
    },
    {
        title: 'Service Tools',
        icon: 'icon-audit.svg',
        childAccountOnly: true,
        type: 'users',
        subMenu: [
            {
                name: '/audit',
                title: 'Audit',
                sidebarMenu: {
                    openSubMenu: true,
                    items: [
                        {
                            name: '/audit/orders',
                            title: 'Orders',
                            permission: 'service-tool:audit:orders',
                        },
                        {
                            name: '/audit/event-monitoring',
                            title: 'Event',
                            permission: 'service-tool:audit:event',
                        },
                        {
                            name: '/audit/monitoring',
                            title: 'Monitoring',
                            permission: 'service-tool:audit:monitoring',
                        },
                        {
                            name: '/audit/browsers-statistic',
                            title: 'Browsers Statistic',
                            permission: 'service-tool:audit:browser-statistic',
                        },
                        {
                            name: '/audit/test-monitoring',
                            title: 'Test Monitoring',
                            permission: 'service-tool:audit:test-monitoring',
                        },
                    ],
                },
            },
            {
                name: '/scoring',
                title: 'Scoring',
                sidebarMenu: {
                    openSubMenu: true,
                    items: [
                        {
                            name: '/scoring/statistics',
                            title: 'Statistics',
                            permission: 'service-tool:scoring:statistics',
                        },
                        {
                            name: '/scoring/block-list',
                            title: 'List Settings',
                            permission: 'service-tool:scoring:settings',
                        },
                        {
                            name: '/scoring/static-list',
                            title: 'Static List',
                            permission: 'service-tool:scoring:static-ips',
                        },
                    ],
                },
            },
        ],
    },
    {
        title: '3-rd Party Services',
        icon: 'icon-services.svg',
        childAccountOnly: true,
        subMenu: [
            {
                name: '/integrations/connections',
                title: 'Connections',
                permission: 'esc:integrations:connections',
            },
            {
                name: '/integrations/data-stream',
                title: 'Data Stream',
                permission: 'esc:integrations:data-stream',
            },
            {
                name: '/integrations/logs',
                title: 'Logs',
                permission: 'esc:integrations:logs',
            },
            ...(LocalStorage.get('seo-tool')
                ? [
                      {
                          name: '/integrations/seo-tool',
                          title: 'Seo Tool',
                          permission: 'esc:integrations:connections',
                      },
                  ]
                : []),
        ],
    },

    {
        title: 'IAM',
        icon: 'icon-user-management.svg',
        subMenu: [
            {
                name: '/iam/users',
                title: 'Users',
                allowForMaster: true,
                permission: 'iam:users:tab',
            },
            {
                name: '/iam/accounts',
                title: 'Accounts',
                allowForMaster: true,
                permission: 'iam:accounts:tab',
            },
            {
                name: '/iam/roles',
                title: 'Roles',
                allowForMaster: true,
                permission: 'iam:roles:tab',
            },
            {
                name: '/config/service-tokens',
                title: 'Service Tokens',
                permission: 'iam:api-tokens:token',
                childAccountOnly: true,
            },
            {
                name: '/services',
                title: 'Services',
                masterAccountOnly: true,
                hasSomePermission: ['sm:services:service:view'],
            },
        ],
    },
    {
        title: 'Schema Monitor',
        icon: 'icon-scheme-monitor.svg',
        childAccountOnly: true,
        subMenu: [
            {
                name: '/schema-monitor',
                title: 'Schema Monitor',
                permission: 'schema-monitor:connections:connection',
            },
        ],
    },
    {
        title: 'Activity Log',
        icon: 'icon-activity-log.svg',
        subMenu: [
            {
                name: '/activity-log',
                title: 'Activity Log',
                hasSomePermission: [
                    'iam:users:tab:view',
                    'iam:roles:tab:view',
                    'iam:accounts:tab:view',
                    'dw:dsm:data-sources:view',
                    'dw:dsm:users:view',
                ],
            },
        ],
    },
    ...dsMenu,
    {
        title: 'Label Management',
        icon: 'icon-label.svg',
        subMenu: [
            {
                name: '/labels',
                title: 'Label Management',
                masterAccountOnly: true,
            },
        ],
    },
    {
        title: 'DDDM Tools',
        icon: 'icon-ddd-tools.svg',
        childAccountOnly: true,
        hasSomePermission: [
            'dddm:redistribution:link:view',
            'dddm:what-if:link:view',
            'dddm:pb:redistribution-link:view',
            'dddm:user-profiler:list:view',
        ],
        subMenu: [
            {
                name: '/dddm-tools/fintech-platform',
                title: 'FinTech Platform',
                hasSomePermission: ['dddm:redistribution:link:view', 'dddm:what-if:link:view', 'dddm:pb:redistribution-link:view'],
            },
            {
                name: '/dddm-tools/user-profiler',
                title: 'User Profiler',
                permission: 'dddm:user-profiler:list:view',
            },
        ],
    },
    {
        title: 'Validation Rules',
        icon: 'icon-validation-rules.svg',
        childAccountOnly: true,
        hasSomePermission: ['vr:datasets:dataset:view', 'vr:suites:suite:view'],
        subMenu: [
            {
                name: '/validation-rules/validation-suites',
                title: 'Validation Suites',
                permission: 'vr:suites:suite:view',
            },
            {
                name: '/validation-rules/datasets',
                title: 'Datasets',
                permission: 'vr:datasets:dataset:view',
            },
        ],
    },
    ...(LocalStorage.get('templates')
        ? [
              {
                  title: 'Layout Demo',
                  icon: 'icon-dsm.svg',
                  subMenu: [
                      {
                          name: '/layout-demo/layout-list',
                          title: 'Layout Demo',
                      },
                      {
                          name: '/layout-demo/layout-unit-page',
                          title: 'Layout Unit',
                      },
                      {
                          name: '/layout-demo/layout-unit-page-tabs',
                          title: 'Layout Unit Tabs',
                      },
                  ],
              },
          ]
        : []),
];
if (LocalStorage.get('cockpit')) {
    menu.push({
        title: 'Cockpit',
        icon: 'icon-dsm.svg',
        subMenu: [
            {
                name: '/cockpit',
                title: 'Cockpit',
            },
        ],
    });
}

export default menu;
